export const locations = [
  'ConsigneeCity',
  'ConsigneeState',
  'ConsigneeZipCode',
  'ShipperAddress'
]

export const transits = ['CountryOfOrigin', 'DestinationPort', 'ForeignPort']

export const fieldsConfig = {
  Consignee: 'keyword',
  Shipper: 'keyword',
  MarksAndNumbers: 'keyword',
  ProductKeywords: 'keyword',
  ShipperAddress: 'keyword',
  HSCode: 'filter',
  ConsigneeCity: 'filter',
  ConsigneeState: 'filter',
  ConsigneeZipCode: 'filter',
  ForeignPort: 'filter',
  DestinationPort: 'filter',
  CountryOfOrigin: 'filter',
  CarrierCode: 'filter'
}
