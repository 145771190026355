<template>
  <div
    v-loading="isFetching"
    class="card-main">
    <section
      v-for="key in itemsByDateKeys"
      :key="key">
      <div class="header-stamp">
        <span v-if="isToday(key)">Today</span>
        <span v-else>{{ key }}</span>
        <span class="list-item-count">{{ itemsByDate[key].length }}</span>
      </div>
      <slot
        :items="itemsByDate[key]"
        name="items" />
    </section>
    <div
      v-if="!itemsByDateKeys.length && !isFetching">
      <div class="empty-state">
        <empty-state-notification :title="emptyMessage"/>
        <p>{{ subMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyStateNotification from '~/components/common/EmptyStateNotification'
export default {
  name: 'DateSeparator',
  components: {
    EmptyStateNotification
  },
  props: {
    isFetching: {
      type: Boolean,
      required: true
    },
    emptyMessage: {
      type: String,
      default: 'No items available'
    },
    subMessage: {
      type: String,
      default: 'Please select another date range.'
    },
    items: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    itemsByDate() {
      return this.items.reduce((acc, item) => {
        const formattedDate = this.$date.format(
          item[this.date] * 1000,
          'yyyy-MM-dd'
        )
        if (!acc[formattedDate]) {
          acc[formattedDate] = []
        }
        acc[formattedDate] = [...acc[formattedDate], item]
        return acc
      }, {})
    },
    itemsByDateKeys() {
      return Object.keys(this.itemsByDate)
    }
  },
  methods: {
    isToday(day) {
      return day === this.$date.format(new Date().getTime(), 'yyyy-MM-dd')
    }
  }
}
</script>
