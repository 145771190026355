<template>
  <component
    :is="root ? 'div' : 'li'"
    :class="groupClass">
    <button
      v-if="!root"
      :class="buttonClass"
      class="control-button"
      @click="isOpen = !isOpen"/>
    <div class="logic-container">
      <div class="logic-type">{{ keyword.o }}</div>
      <ul
        v-if="root || isOpen"
        class="criteria-lists">
        <component
          v-for="(keyword, index) in keyword.i"
          :key="index"
          :is="keyword.i ? 'advanced-group' : 'advanced-condition'"
          :keyword="keyword" />
      </ul>
    </div>
  </component>
</template>

<script>
import AdvancedCondition from './AdvancedCondition'

export default {
  name: 'AdvancedGroup',
  components: {
    AdvancedCondition
  },
  props: {
    keyword: {
      type: Object,
      required: true
    },
    root: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    groupClass() {
      return this.root ? 'group-criteria' : 'sub-group'
    },
    buttonClass() {
      return this.isOpen ? 'collapsed' : 'expand'
    }
  }
}
</script>
<style src="~/assets/scss/components/advanced-search/advanced-search-criteria.scss" lang="scss"/>
