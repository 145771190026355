<template>
  <div class="criteria-container">
    <div class="criteria-item keyword">
      <h3>Keywords</h3>
      <div v-if="Array.isArray(criteria.keywords)">
        <ul v-if="criteria.keywords.length">
          <li
            v-for="(keyword, index) in criteria.keywords"
            :key="index">
            <template v-if="index !== 0">
              {{ keyword.operator }}
            </template>
            {{ keyword.type }}
            <strong>{{ keyword.keyword }}</strong> in {{ formatField(keyword.field) }}
          </li>
        </ul>
        <ul v-else>
          <li>N/A</li>
        </ul>
      </div>
      <div
        v-else
        class="advanced-search-criteria">
        <advanced-group
          :keyword="criteria.keywords | updateKeywordStructure"
          root />
      </div>
    </div>
    <div class="criteria-item locations">
      <h3>Locations</h3>
      <ul v-if="locations.length">
        <li
          v-for="location in locations"
          :key="location.keyword">{{ location.type }} {{ location.keyword | stateFilter | upperCase }} in {{ formatField(location.field) }}</li>
      </ul>
      <ul v-else>
        <li>N/A</li>
      </ul>
    </div>
    <div class="criteria-item transit">
      <h3>Transit Details</h3>
      <ul v-if="transits.length">
        <li
          v-for="transit in transits"
          :key="transit.keyword">{{ transit.type }} {{ transit.keyword | stateFilter | upperCase }} in {{ formatField(transit.field) }}</li>
      </ul>
      <ul v-else>
        <li>N/A</li>
      </ul>
    </div>
    <div
      class="criteria-item miscellaneous">
      <h3>Miscellaneous</h3>
      <ul v-if="criteria.misc.length">
        <li
          v-for="misc in criteria.misc"
          :key="misc">{{ misc | startCase }}</li>
      </ul>
      <ul v-else>
        <li>N/A</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { statesKeys } from '~/utils/address'
import { upperCase, startCase } from 'lodash'
import { updateKeywordStructure } from '~/utils/advancedSearch'
import { locations, transits } from '~/config/field-categories'
import AdvancedGroup from '~/components/common/AdvancedKeywords/AdvancedGroup'

export default {
  name: 'CollapseCriteria',
  filters: {
    upperCase,
    startCase,
    updateKeywordStructure,
    stateFilter(val) {
      return statesKeys[val] || val
    }
  },
  components: {
    AdvancedGroup
  },
  props: {
    criteria: {
      type: Object,
      required: true
    }
  },
  computed: {
    locations() {
      const includes = this.criteria.include
        .filter(value => locations.indexOf(value.field) !== -1)
        .map(value => ({ type: 'Include', ...value }))
      const excludes = this.criteria.exclude
        .filter(value => locations.indexOf(value.field) !== -1)
        .map(value => ({ type: 'Exclude', ...value }))
      return [...includes, ...excludes]
    },
    transits() {
      const includes = this.criteria.include
        .filter(value => transits.indexOf(value.field) !== -1)
        .map(value => ({ type: 'Include', ...value }))
      const excludes = this.criteria.exclude
        .filter(value => transits.indexOf(value.field) !== -1)
        .map(value => ({ type: 'Exclude', ...value }))
      return [...includes, ...excludes]
    }
  },
  methods: {
    formatField(field) {
      const { fields } = this.$store.state.views
      return fields[field] !== undefined
        ? fields[field].displayName
        : startCase(field)
    }
  }
}
</script>
