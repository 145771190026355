<template>
  <li>{{ parsedKeyword.type }} <strong>{{ parsedKeyword.keyword }}</strong> in {{ parsedKeyword.field }}</li>
</template>

<script>
import { startCase } from 'lodash'

export default {
  name: 'AdvancedCondition',
  props: {
    keyword: {
      type: Object,
      required: true
    }
  },
  computed: {
    parsedKeyword() {
      let { field, type, keyword } = this.keyword
      const { fields } = this.$store.state.views
      keyword = keyword.map(item => `"${item}"`).join(', ')

      return {
        field:
          fields[field] !== undefined
            ? fields[field].displayName
            : startCase(field),
        type: startCase(type),
        keyword
      }
    }
  }
}
</script>
